import React, { useCallback, useState } from "react";
import { getAxios } from "../../axios/Axios";
import request from "../../request/request";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import Config from "../../config/Config";
import { useNavigate } from "react-router-dom";

const menuItems = [
  { label: "Dashboard", path: "/dashboard" },

  {
    label: "Website",
    subMenu: [
      { label: "Website List", path: "/website-list" },
    ],
  },
  {
    label: "Category Management",
    subMenu: [
      { label: "Global Category List", path: "/global-category-list" },
    ],
  },
  {
    label: "Product Management",
    subMenu: [
      { label: "Global Product List", path: "/global-product-list" },
    ],
  },
  {
    label: "My Account",
    subMenu: [
      { label: "Edit Profile", path: "/dashboard/editprofile" },
      { label: "Change Password", path: "/" },
    ],
  },
  { label: "Data", subMenu: [{ label: "Party Listing", path: "/party-listing" }] },
  {
    label: "Posts",
    subMenu: [
      { label: "App Posting", path: "/dashboard/app-posting" },
      { label: "Create A Post", path: "/dashboard/create-post" },
    ],
  },
  {
    label: "Inquiry",
    subMenu: [{ label: "Inquiry", path: "/dashboard/inquiry" }],
  },
  {
    label: "Security",
    subMenu: [{ label: "Manage User Roles", path: "/dashboard" }],
  },
  {
    label: "UI/UX",
    subMenu: [{ label: "Template Features", path: "/dashboard" }],
  },
  // {
  //   label: "Backend",
  //   subMenu: [
  //     { label: "OpenApi(custom)", path: "/" },
  //     { label: "OpenApi(Yancy)", path: "/" },
  //     { label: "Yancy CMS", path: "/" },
  //   ],
  // },
  {
    label: "SEO",
    subMenu: [
      { label: "URL List", path: "/url-list" },
      { label: "Add URL", path: "/url-screen" },
    ],
  },
  
];

function SidebarMenu({ setUser, setLogin, setMenuShow, menuShow,user }) {
  const navigate = useNavigate();
  const [currentOpen, setCurrentOpen] = useState(null);
  const [currentOpenSubMenu, setCurrentOpenSubMenu] = useState('');

  const handleSubMenuClick = useCallback(
    (index) => {
      if (currentOpen === index) {
        setCurrentOpen(null);
      } else {
        setCurrentOpen(index);
      }
    },
    [currentOpen]
  );

  const handleMenuItemClick = useCallback(
    (path) => {
      if (currentOpenSubMenu === path) {
        setCurrentOpenSubMenu('');
        
      } else {
        setCurrentOpenSubMenu(path);
      }
    },
    [currentOpenSubMenu]
  );

  const logoutBtn = async () => {
    const res = await getAxios(request.logout);
    if (res.data.apiresponse.type === "OK") {
      setUser(null)
      setLogin(true);
      navigate("/");
    }
  };

  
  return (
    <>
      <header className="my-sidebar-menu">
        <nav
          id="sidebarMenu"
          className={` ${
            menuShow ? "menu-animation" : "menu-animation-out"
          } sidebar `}
        >
          <Sidebar>
            <Menu>
              {menuItems.map((item, index) => (
                <React.Fragment key={index}>
                  {item.subMenu ? (
                    <SubMenu
                      label={item.label}
                      open={currentOpen === index}
                      onOpenChange={() => handleSubMenuClick(index)}
                    >
                      {item.subMenu.map((subItem, subIndex) => (
                        <MenuItem
                          key={subIndex}
                          onClick={() => {
                            handleMenuItemClick(subItem.path);
                            navigate(subItem.path);
                          }}
                          active={currentOpenSubMenu === subItem.path}
                        >
                          {subItem.label}
                        </MenuItem>
                      ))}
                    </SubMenu>
                  ) : (
                    <MenuItem>
                      <Link to={item.path}>{item.label}</Link>
                    </MenuItem>
                  )}
                </React.Fragment>
              ))}
              {/* <MenuItem onClick={logoutBtn}>Log Out</MenuItem> */}
            </Menu>
          </Sidebar>
        </nav>
        <nav
          id="main-navbar"
          className="navbar navbar-expand-lg navbar-light fixed-top"
        >
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#sidebarMenu"
              aria-controls="sidebarMenu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="fas fa-bars"></i>
            </button>

            <a className="navbar-brand" href="/" style={{marginRight: '50px'}}>
              <img
                src={Config.babusiyaLogo}
                height="36"
                alt=""
                loading="lazy"
              />
            </a>
            <i
              className="fa-solid d-none d-lg-block fa-xl fa-bars cursor-pointer"
              onClick={() => setMenuShow(!menuShow)}
            ></i>

            <ul className="navbar-nav ms-auto d-flex flex-row">
              <li className="nav-item dropdown" style={{ top: "8px" }}>
                <p className="userInfo">
                  <span style={{fontWeight: 700}}>{user?.fullname}</span><br/>ID:{' '}{user?.partyId}
                </p>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle hidden-arrow d-flex align-items-center"
                  href="/"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-mdb-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={user?.profilePic}
                    className="rounded-circle img"
                    height="40"
                    alt=""
                    loading="lazy"
                  />
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-end end-0"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <p className="dropdown-item" style={{ cursor: "pointer", fontWeight: 700 }}>
                      {user?.fullname}
                    </p>
                  </li>
                  <li>
                    <p className="dropdown-item" style={{ cursor: "pointer" }}>
                    ID:{' '}{user?.partyId}
                    </p>
                  </li>
                  <li>
                    <p
                      className="dropdown-item"
                      onClick={logoutBtn}
                      style={{ cursor: "pointer" }}
                    >
                      Logout
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
}

export default SidebarMenu;
